import {
  confRootAccess,
  confRateCardManagementAccess,
  confEmailTemplateManagementAccess,
  confEmailNotificationsAccess,
  confNoneWorkingDaysManagementAccess,
  confHiqoContractsManagementAccess,
  confRateChangeNotificationsAccess,
  confProjectLeadManagementAccess,
  confUserManagementAccess,
  confDiscountSurchargeManagementAccess,
  deliveryRootModuleAccess,
  deliveryResourcesAccess,
  deliveryReviewsManagementAccess,
  deliveryReviewsReportAccess,
  reconciliationReportAccess,
  billingReportsRootAccess,
  billingReportsProjectToAccess,
  billingReportsSummaryToAccess,
  billingReportsSummaryGmAccess,
  internalToAccess,
  billingReportsToRootAccess,
  billingReportsDtoRootAccess,
  billingReportsProjectDtoAccess,
  billingReportsSummaryDtoAccess,
  salesReportsAccess,
  deliveryUtilizationAccess,
  utilizationsByMonthAccess,
  generateTodayUtilizationAllReportAccess,
  generateTodayUtilizationMSQReportAccess,
  generateTodayUtilizationLEDReportAccess,
  generateTodayUtilizationWAWReportAccess,
  utilizationForMonthAccess,
  utilizationForYearAccess,
  deliveryBookingRootAccess,
  allResourceBookingReportAccess,
  msqResourceBookingReportAccess,
  ledResourceBookingReportAccess,
  wawResourceBookingReportAccess,
  salesUtilizationRootAccess,
  todaysCFRReportAccess,
  staffReportsRootAccess,
  currentPayrollReportAccess,
  annualPayrollReportAccess,
  plCommissionReportAccess,
  confExchangeRatesManagementAccess,
  confCommissionRatesAccess,
  ptoReportAccess,
  todayBillingReportAccess,
  confCategoryManagement,
  resourceManagementReportAccess,
  configurationGuaranteedAccessRolesForSubRoutes,
  reportsGuaranteedAccessRolesForSubRoutes,
  deliveryGuaranteedAccessRolesForSubRoutes,
  mentoringCompensationReportAccess,
  requiredReviewsReportAccess,
} from 'core/auth/guaranteedAccessRoles';

import { actionsConstants } from './constants';

const getActiveDevcenters = (devcenterShortName) => {
  const devcenters = JSON.parse(window.localStorage.getItem('active-devcenters'));
  if (!devcenterShortName) {
    return Object.values(devcenters);
  }
  return devcenters ? [devcenters[devcenterShortName]] : [];
};

export default [
  {
    name: 'conf',
    route: 'configurations',
    guaranteedAccessRoles: confRootAccess,
    guaranteedAccessRolesForSubRoutes: configurationGuaranteedAccessRolesForSubRoutes,
    getIsActive: () => Object.keys(configurationGuaranteedAccessRolesForSubRoutes)
      .some((route) => window.location.pathname.includes(route)),
    dropdownItems: [
      {
        items: [
          {
            guaranteedAccessRoles: confRateCardManagementAccess.roles,
            route: confRateCardManagementAccess.route,
            text: 'Rate Card Management',
          },
          {
            guaranteedAccessRoles: confRateChangeNotificationsAccess.roles,
            route: confRateChangeNotificationsAccess.route,
            text: 'Rate Change Notifications',
          },
          {
            guaranteedAccessRoles: confUserManagementAccess.roles,
            route: confUserManagementAccess.route,
            text: 'User Management',
          },
          {
            guaranteedAccessRoles: confCommissionRatesAccess.roles,
            route: confCommissionRatesAccess.route,
            text: 'Commission Rates',
          },
          {
            guaranteedAccessRoles: confExchangeRatesManagementAccess.roles,
            route: confExchangeRatesManagementAccess.route,
            text: 'Exchange Rates Management',
          },
          {
            guaranteedAccessRoles: confNoneWorkingDaysManagementAccess.roles,
            route: confNoneWorkingDaysManagementAccess.route,
            text: 'Non-Working Days Management',
          },
          {
            guaranteedAccessRoles: confHiqoContractsManagementAccess.roles,
            route: confHiqoContractsManagementAccess.route,
            text: 'Hiqo Contracts Management',
          },
          {
            guaranteedAccessRoles: confEmailTemplateManagementAccess.roles,
            route: confEmailTemplateManagementAccess.route,
            text: 'Email Template Management',
          },
          {
            guaranteedAccessRoles: confEmailNotificationsAccess.roles,
            route: confEmailNotificationsAccess.route,
            text: 'Email Notifications',
          },
          {
            guaranteedAccessRoles: confCategoryManagement.roles,
            route: confCategoryManagement.route,
            text: 'Category Management',
          },
          {
            guaranteedAccessRoles: confDiscountSurchargeManagementAccess.roles,
            route: confDiscountSurchargeManagementAccess.route,
            text: 'Discount & Surcharge Management',
          },
        ],
      },
    ],
  },
  {
    name: 'delivery',
    guaranteedAccessRoles: deliveryRootModuleAccess,
    guaranteedAccessRolesForSubRoutes: deliveryGuaranteedAccessRolesForSubRoutes,
    route: ['delivery', 'devstaff-reviews'],
    getIsActive: () => [
      ...Object.keys(deliveryGuaranteedAccessRolesForSubRoutes)
        .filter((route) => route !== `/${deliveryReviewsReportAccess.route}`),
    ]
      .some((route) => window.location.pathname.includes(route)),
    dropdownItems: [
      {
        items: [
          {
            guaranteedAccessRoles: deliveryResourcesAccess.roles,
            route: deliveryResourcesAccess.route,
            text: 'Resources',
          },
          {
            guaranteedAccessRoles: confProjectLeadManagementAccess.roles,
            route: confProjectLeadManagementAccess.route,
            text: 'Project Lead Management',
          },
          {
            guaranteedAccessRoles: deliveryReviewsManagementAccess.roles,
            route: deliveryReviewsManagementAccess.route,
            text: 'Reviews',
          },
        ],
      },
    ],
  },
  {
    name: 'reports',
    getIsActive: () => Object.keys(reportsGuaranteedAccessRolesForSubRoutes)
      .some((route) => window.location.pathname.includes(route)),
    guaranteedAccessRoles: billingReportsRootAccess,
    guaranteedAccessRolesForSubRoutes: reportsGuaranteedAccessRolesForSubRoutes,
    route: ['reports', 'reconciliation', 'devstaff-reviews'],
    dropdownItems: [
      [
        {
          title: 'TO Reports',
          guaranteedAccessRoles: billingReportsToRootAccess,

          items: [
            {
              guaranteedAccessRoles: billingReportsProjectToAccess.roles,
              route: `${billingReportsProjectToAccess.route}`,
              text: 'Billing Report for TO',
            },
            {
              guaranteedAccessRoles: billingReportsSummaryToAccess.roles,
              route: `${billingReportsSummaryToAccess.route}/billable-activities`,
              text: 'Billing Summary TO',
            },
            {
              guaranteedAccessRoles: billingReportsSummaryGmAccess.roles,
              route: `${billingReportsSummaryGmAccess.route}/billable-activities`,
              text: 'GM Summary',
            },
          ],
        },

        {
          title: 'DTO Reports',
          guaranteedAccessRoles: billingReportsDtoRootAccess,
          items: [
            {
              guaranteedAccessRoles: billingReportsProjectDtoAccess.roles,
              route: `${billingReportsProjectDtoAccess.route}`,
              text: 'Billing Report for DTO',
            },
            {
              guaranteedAccessRoles: billingReportsSummaryDtoAccess.roles,
              route: `${billingReportsSummaryDtoAccess.route}/billable-activities`,
              text: 'Billing Summary DTO',
            },
            {
              guaranteedAccessRoles: internalToAccess.roles,
              route: `${internalToAccess.route}`,
              text: 'HiQo Task Orders',
            },
          ],
        },
      ],
      [
        {
          title: 'Delivery Utilization',
          guaranteedAccessRoles: deliveryUtilizationAccess,
          items: [
            {
              guaranteedAccessRoles: utilizationsByMonthAccess.roles,
              route: utilizationsByMonthAccess.route,
              text: 'Utilization by Month',
            },
            {
              guaranteedAccessRoles: generateTodayUtilizationAllReportAccess,
              action: actionsConstants.GENERATE_UTILIZATION_REPORT,
              actionArguments: () => ({
                devcenterIds: getActiveDevcenters(),
              }),
              text: "Today's Utilization ALL",
            },
            {
              guaranteedAccessRoles: generateTodayUtilizationMSQReportAccess,
              action: actionsConstants.GENERATE_UTILIZATION_REPORT,
              actionArguments: () => ({
                devcenterIds: getActiveDevcenters('MSQ'),
              }),
              text: "Today's Utilization MSQ",
            },
            {
              guaranteedAccessRoles: generateTodayUtilizationLEDReportAccess,
              action: actionsConstants.GENERATE_UTILIZATION_REPORT,
              actionArguments: () => ({
                devcenterIds: getActiveDevcenters('SPB'),
              }),
              text: "Today's Utilization LED",
            },
            {
              guaranteedAccessRoles: generateTodayUtilizationWAWReportAccess,
              action: actionsConstants.GENERATE_UTILIZATION_REPORT,
              actionArguments: () => ({
                devcenterIds: getActiveDevcenters('POL'),
              }),
              text: "Today's Utilization POL",
            },
            {
              guaranteedAccessRoles: utilizationForMonthAccess.roles,
              route: `${utilizationForMonthAccess.route}`,
              text: 'Utilization for Month',
            },
            {
              guaranteedAccessRoles: utilizationForYearAccess.roles,
              route: utilizationForYearAccess.route,
              text: 'Utilization for Year',
            },
          ],
        },
        {
          title: 'Delivery Bookings/Billings',
          guaranteedAccessRoles: deliveryBookingRootAccess,
          items: [
            {
              guaranteedAccessRoles: allResourceBookingReportAccess,
              action: actionsConstants.GENERATE_BOOKING_REPORT,
              actionArguments: () => ({
                devcenterId: getActiveDevcenters(),
              }),
              text: 'Resource Bookings 3mo ALL',
            },
            {
              guaranteedAccessRoles: msqResourceBookingReportAccess,
              action: actionsConstants.GENERATE_BOOKING_REPORT,
              actionArguments: () => ({
                devcenterId: getActiveDevcenters('MSQ'),
              }),
              text: 'Resource Bookings 3mo MSQ',
            },
            {
              guaranteedAccessRoles: ledResourceBookingReportAccess,
              action: actionsConstants.GENERATE_BOOKING_REPORT,
              actionArguments: () => ({
                devcenterId: getActiveDevcenters('SPB'),
              }),
              text: 'Resource Bookings 3mo LED',
            },
            {
              guaranteedAccessRoles: wawResourceBookingReportAccess,
              action: actionsConstants.GENERATE_BOOKING_REPORT,
              actionArguments: () => ({
                devcenterId: getActiveDevcenters('POL'),
              }),
              text: 'Resource Bookings 3mo POL',
            },
            {
              guaranteedAccessRoles: todayBillingReportAccess.roles,
              route: todayBillingReportAccess.route,
              text: "Today's Billings",
            },
          ],
        },
      ],
      [
        {
          title: 'Sales Utilization',
          guaranteedAccessRoles: salesUtilizationRootAccess,
          items: [
            {
              guaranteedAccessRoles: todaysCFRReportAccess.roles,
              route: `${todaysCFRReportAccess.route}`,
              text: "Today's CFR",
            },
          ],
        },
        {
          title: 'Sales Reports',
          guaranteedAccessRoles: salesReportsAccess.roles,
          items: [
            {
              guaranteedAccessRoles: salesReportsAccess.roles,
              route: `${salesReportsAccess.route}`,
              text: 'Commission Summary Report',
            },
          ],
        },
      ],
      [
        {
          title: 'Staff Reports',
          guaranteedAccessRoles: staffReportsRootAccess,
          items: [
            {
              guaranteedAccessRoles: reconciliationReportAccess.roles,
              route: reconciliationReportAccess.route,
              text: 'Reconciliation Liability Report',
            },
            {
              guaranteedAccessRoles: currentPayrollReportAccess.roles,
              route: `${currentPayrollReportAccess.route}`,
              text: 'Current Payroll Report',
            },
            {
              guaranteedAccessRoles: annualPayrollReportAccess.roles,
              route: `${annualPayrollReportAccess.route}`,
              text: 'Annual Payroll Report',
            },
            {
              guaranteedAccessRoles: plCommissionReportAccess.roles,
              route: `${plCommissionReportAccess.route}`,
              text: 'Project Lead Commission Report',
            },
            {
              guaranteedAccessRoles: ptoReportAccess.roles,
              route: ptoReportAccess.route,
              text: 'PTO Report',
            },
            {
              guaranteedAccessRoles: deliveryReviewsReportAccess.roles,
              route: deliveryReviewsReportAccess.route,
              text: 'Staff Reviews',
            },
            {
              guaranteedAccessRoles: requiredReviewsReportAccess.roles,
              route: requiredReviewsReportAccess.route,
              text: 'Required Reviews',
            },
            {
              guaranteedAccessRoles: resourceManagementReportAccess.roles,
              route: resourceManagementReportAccess.route,
              text: 'Resource Management',
            },
            {
              guaranteedAccessRoles: mentoringCompensationReportAccess.roles,
              route: mentoringCompensationReportAccess.route,
              text: 'Mentoring Compensation',
            },
          ],
        },
      ],
    ],
  },
];
