import { waitForAuthorization } from 'core/auth/sagas';

import { selectEntityName, selectErrors } from 'core/delivery/selectors';
import { get } from 'lodash';
import { call, takeLatest, select, put, take } from 'redux-saga/effects';
import { getNotifications } from 'utils/helpers/notifications';
import {
  createUploadFileChannel, executeMutation,
  executeQuery,
  parseError,
} from 'utils/request';

import { attachmentsActions, attachmentsTypes } from './actions';
import { queryConfig } from './queries';

function* getDevstaffAttachmentsRecords({ payload }) {
  try {
    const query = queryConfig.getDevstaffAttachmentsRecords;
    const options = {
      query,
      variables: {
        devstaffId: Number(payload.devstaffId),
      },
    };
    const { devstaffAttachmentRecords } = yield call(executeQuery, options);

    yield put(attachmentsActions.getDevstaffAttachmentsRecordsSuccess({
      records: devstaffAttachmentRecords || [],
      memoDevstaffId: payload.devstaffId,
    }));
  } catch (error) {
    const entityName = yield select(selectEntityName);
    getNotifications({
      error: error.message,
      containerId: entityName,
    });
    yield put(attachmentsActions.getDevstaffAttachmentsRecordsFail());
  }
}

function* createDevstaffAttachmentRecord({ payload: { attachmentRecord: record, devstaffId } }) {
  try {
    const mutation = queryConfig.createDevstaffAttachment;
    const options = {
      mutation,
      variables: {
        devstaffId: Number(devstaffId),
        fields: {
          fileName: get(record, 'file.name', ''),
          extension: get(record, 'file.extension', ''),
          description: get(record, 'description', ''),
        },
      },
    };
    const { createAttachmentRecord } = yield call(executeMutation, options);

    const uploadFileStatus = yield call(uploadFile, {
      fileUploadData: get(createAttachmentRecord, 'fileUploadData', ''),
      file: get(record, 'file.fileData', {}),
    });

    const attachmentRecord = get(createAttachmentRecord, 'attachmentRecord', {});

    if (uploadFileStatus) {
      yield put(attachmentsActions.createAttachmentRecordSuccess({
        record: attachmentRecord,
        memoDevstaffId: devstaffId,
      }));
    } else {
      yield put(attachmentsActions.deleteAttachmentRecord({
        attachmentRecordId: get(attachmentRecord, 'attachmentRecordId', ''),
        isCreateAttachmentRecord: true,
      }));

      throw new Error('File upload error. Please try again.', { cause: 'fileError' });
    }
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createDevstaffAttachmentRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createDevstaffAttachmentRecordError = yield call(parseError, error, options);

    yield put(attachmentsActions.createAttachmentRecord({
      error: {
        createDevstaffAttachmentRecordError,
      },
    }));
  }
}

function* uploadFile({
  fileUploadData,
  file,
}) {
  try {
    const channel = yield call(createUploadFileChannel, fileUploadData, file);

    while (true) {
      const action = yield take(channel);
      const { err: uploadFileError, success } = action;

      if (uploadFileError) {
        return false;
      }

      if (success) {
        return success;
      }
    }
  } catch (error) {
    return false;
  }
}

function* deleteDevstaffAttachmentRecord({ payload: {
  attachmentRecordId,
  isCreateAttachmentRecord,
} }) {
  try {
    const mutation = queryConfig.deleteDevstaffAttachment;
    const options = {
      mutation,
      variables: {
        attachmentRecordId,
      },
    };
    yield call(executeMutation, options);
    yield put(attachmentsActions.deleteAttachmentRecordSuccess({
      attachmentRecordId,
      isCreateAttachmentRecord,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'deleteDevstaffAttachmentRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const deleteDevstaffAttachmentRecordError = yield call(parseError, error, options);

    yield put(attachmentsActions.deleteAttachmentRecordFail({
      error: {
        deleteDevstaffAttachmentRecordError,
      },
    }));
  }
}

function* updateDevstaffAttachmentRecord({ payload: { attachmentRecord: record, devstaffId } }) {
  try {
    const mutation = queryConfig.updateDevstaffAttachment;
    const options = {
      mutation,
      variables: {
        attachmentRecordId: get(record, 'attachmentRecordId', ''),
        fields: {
          description: get(record, 'description', ''),
        },
      },
    };

    const { updateAttachmentRecord } = yield call(executeMutation, options);

    yield put(attachmentsActions.updateAttachmentRecordSuccess({
      record: get(updateAttachmentRecord, 'attachmentRecord', {}),
      memoDevstaffId: devstaffId,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateDevstaffAttachmentError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateDevstaffAttachmentError = yield call(parseError, error, options);

    yield put(attachmentsActions.updateAttachmentRecordFail({
      error: {
        updateDevstaffAttachmentError,
      },
    }));
  }
}

function* getDevstaffAttachmentsRecordsWatcher() {
  yield takeLatest(attachmentsTypes.GET_ATTACHMENTS_RECORDS, waitForAuthorization(getDevstaffAttachmentsRecords));
}

function* createAttachmentRecordWatcher() {
  yield takeLatest(attachmentsTypes.CREATE_ATTACHMENT_RECORD, waitForAuthorization(createDevstaffAttachmentRecord));
}

function* deleteAttachmentRecordWatcher() {
  yield takeLatest(attachmentsTypes.DELETE_ATTACHMENT_RECORD, waitForAuthorization(deleteDevstaffAttachmentRecord));
}

function* updateAttachmentRecordWatcher() {
  yield takeLatest(attachmentsTypes.UPDATE_ATTACHMENT_RECORD, waitForAuthorization(updateDevstaffAttachmentRecord));
}

export default [
  getDevstaffAttachmentsRecordsWatcher,
  createAttachmentRecordWatcher,
  deleteAttachmentRecordWatcher,
  updateAttachmentRecordWatcher,
];
