import { waitForAuthorization } from 'core/auth/sagas';

import {
  clientRateCardUpdateNotificationsTypes,
} from 'core/configurations/client-rate-card-update-notifications/actions';
import {
  createRatecardNotificationsVersion,
  deleteRatecardNotificationsVersion,
  getRateCardsNotifications,
  sendRatecardNotificationsEmails,
  updateRateCardNotifications,
  checkRateCardNotificationsStatus,
  updateRateCardNotificationsStatus,
} from 'core/configurations/client-rate-card-update-notifications/sagas';
import { emailTemplateNotificationsTypes } from 'core/configurations/email-template-notifications/actions';
import {
  getEmailTemplateNotifications,
  updateEmailTemplateNotifications,
  createEmailTemplateNotificationsVersion,
  deleteEmailTemplateNotificationsVersion,
  checkEmailsNotificationsStatus,
  updateEmailsNotificationsStatus,
  sendEmailTemplateNotificationsEmails,
} from 'core/configurations/email-template-notifications/sagas';
import { filesActions } from 'core/files/actions';
import { modalConductorActions } from 'core/modal-conductor/actions';
import { appStorage } from 'core/storage';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import {
  all,
  put,
  call,
  select,
  takeLatest,
  takeEvery,
  take,
  race,
} from 'redux-saga/effects';
import { getNotifications } from 'utils/helpers/notifications';
import request, {
  executeQuery,
  parseError,
  getRequestUrl,
  multiMutationGetter,
  executeMutation,
  createUploadFileChannel,
} from 'utils/request';

import { sortRates } from 'utils/sortRates';

import { configurationsActionsTypes, configurationsActions } from './actions';

import { queryConfig } from './queries';
import { selectErrors, selectEntityName } from './selectors';
import { mapRates } from './utils';

function* getExchangeRates() {
  try {
    const query = queryConfig.getExchangeRates;
    const options = {
      query,
    };
    const response = yield call(executeQuery, options);

    return yield put(configurationsActions.getExchangeRatesSuccess({
      exchangeRates: response.exchangeRates,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getExchangeRatesError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getExchangeRatesError = yield call(parseError, error, options);

    return yield put(configurationsActions.getExchangeRatesFail({
      error: { getExchangeRatesError },
    }));
  }
}

function* updateExchangeRates({ payload: { fields } }) {
  const entityName = yield select(selectEntityName);

  try {
    const multiMutationOptions = multiMutationGetter({
      mutation: 'updateExchangeRates',
      records: fields,
      inputType: 'ExchangeRateUpdateInput!',
      query: `
      exchangeRates {
        year
        monthData{
          month
          usdRur
          usdPln
        }
       }
        `,
    });

    const response = yield call(executeMutation, multiMutationOptions);
    const exchangeRatesRecords = Object.keys(response).flatMap((key) => response[key].exchangeRates);
    const filteredExchangeRatesRecords = {};
    exchangeRatesRecords.forEach((item) => {
      filteredExchangeRatesRecords[item.year] = item;
    });
    return yield all([
      put(configurationsActions.updateExchangeRatesSuccess(filteredExchangeRatesRecords)),
    ]);
  } catch (error) {
    const { message } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(configurationsActions.updateExchangeRatesFail({
      error: {
        updateExchangeRatesError: [message || 'unknown'],
      },
    }));
  }
}

function* getCommissionRates() {
  try {
    const query = queryConfig.getCommissionRates;
    const options = {
      query,
    };
    const response = yield call(executeQuery, options);

    return yield put(configurationsActions.getCommissionRatesSuccess({
      commissionRates: response.commissionRates,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getCommissionRatesError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getCommissionRatesError = yield call(parseError, error, options);

    return yield put(configurationsActions.getCommissionRatesFail({
      error: { getCommissionRatesError },
    }));
  }
}

function* removeCommissionRecord({ payload: { recordId, userId } }) {
  try {
    const { removeCommissionRecordError } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.removeCommissionRecord({ recordId });

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const { errors } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: removeCommissionRecordError,
        containerId: 'confirmActionModal',
      });

      return yield put(configurationsActions.removeCommissionRecordFail({
        error: {
          removeCommissionRecordError: listErrors,
        },
      }));
    }

    if (removeCommissionRecordError) {
      removeCommissionRecordError.forEach((error) => toast.dismiss(error));
    }

    return yield all([
      put(configurationsActions.removeCommissionRecordSuccess({
        recordId,
        userId,
      })),
      put(modalConductorActions.clearModal()),
    ]);
  } catch (error) {
    const { message } = error;

    getNotifications({
      error: message,
      containerId: 'confirmActionModal',
    });
    return yield put(configurationsActions.removeCommissionRecordFail({
      error: {
        removeCommissionRecordError: [message || 'unknown'],
      },
    }));
  }
}

function* createCommissionRecord({ payload: { userId } }) {
  const entityName = yield select(selectEntityName);

  try {
    const { createCommissionRecordError } = yield select(selectErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');

    const query = queryConfig.createCommissionRecord({ userId });

    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const { errors, data } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        errors,
        activeErrors: createCommissionRecordError,
        containerId: entityName,
      });

      return yield put(configurationsActions.createCommissionRecordFail({
        error: {
          createCommissionRecordError: listErrors,
        },
      }));
    }

    if (createCommissionRecordError) {
      createCommissionRecordError.forEach((error) => toast.dismiss(error));
    }

    const commissionRecord = get(
      data,
      'createCommissionRatesRecord.commissionRatesRecord',
      null
    );

    return yield all([
      put(configurationsActions.createCommissionRecordSuccess({
        userId,
        fields: commissionRecord,
      })),
    ]);
  } catch (error) {
    const { message } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });

    return yield put(configurationsActions.createCommissionRecordFail({
      error: {
        createCommissionRecordError: [message || 'unknown'],
      },
    }));
  }
}

function* updateCommissionRecord({ payload: { userId, fields } }) {
  const entityName = yield select(selectEntityName);

  try {
    const multiMutationOptions = multiMutationGetter({
      mutation: 'updateCommissionRatesRecord',
      idKeys: 'recordId',
      userId,
      records: fields,
      inputType: 'UpdateCommissionRatesRecordInput!',
      query: `
      commissionRatesRecord{
        userId,
        fullName,
        records{
          recordId,
          userId,
          date,
          reportsTo,
          reportsToId,
          baseSal,
          commissions{
            commDirect,
            commDirect1,
            commDirect2,
            commAm,
          }
          stage1{
            stage1Days,
            stage1Perc,
          }
          stage2{
            stage2Days,
            stage2Perc,
          }
        },
      }
      `,
    });

    const response = yield call(executeMutation, multiMutationOptions);

    const userRecords = Object.keys(response).flatMap((key) => response[key].commissionRatesRecord.records);

    return yield all([
      put(configurationsActions.updateCommissionRecordSuccess({
        userId,
        userRecords,
      })),
    ]);
  } catch (error) {
    const { message } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(configurationsActions.updateCommissionRecordFail({
      error: {
        updateCommissionRecordError: [message || 'unknown'],
      },
    }));
  }
}

function* getRateCards({ payload }) {
  const { date, billingAgents } = payload;
  try {
    const options = {
      query: queryConfig.getRateCards,
      variables: {
        year: date,
      },
    };
    const { rateCardsManagementData } = yield call(executeQuery, options);
    const { lastRatesVersion, rateCardsManagement } = rateCardsManagementData;
    const rateCards = mapRates(rateCardsManagement);
    return yield put(configurationsActions.getRateCardsSuccess({
      rateCards,
      billingAgents,
      date,
      lastRates: sortRates(lastRatesVersion),
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getRateCardsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getRateCardsError = yield call(parseError, error, options);

    return yield put(configurationsActions.getRateCardsFail({
      error: { getRateCardsError },
    }));
  }
}

function* getRateCard({ payload }) {
  const { ratecardId } = payload;
  try {
    const options = {
      query: queryConfig.getRateCard(ratecardId),
    };
    const response = yield call(executeQuery, options);
    const rateCard = mapRates([response.node])[0];
    return yield put(configurationsActions.getRateCardSuccess({
      rateCard,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getRateCardError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getRateCardError = yield call(parseError, error, options);

    return yield put(configurationsActions.getRateCardFail({
      error: { getRateCardError },
    }));
  }
}

function* createRateCardRecord({ payload }) {
  const { rateCard, year } = payload;

  try {
    const options = {
      query: queryConfig.createRateCardRecord,
      variables: {
        rateCard,
        year,
      },
    };
    const response = yield call(executeQuery, options);
    const rateCards = mapRates(response.createRateCardRecord.rateCardsRecords.rateCardsManagement);
    const lastRatesVersion = sortRates(response.createRateCardRecord.rateCardsRecords.lastRatesVersion);
    return yield put(configurationsActions.createRateCardRecordSuccess({
      rateCards,
      lastRatesVersion,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createRateCardError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createRateCardError = yield call(parseError, error, options);

    return yield put(configurationsActions.createRateCardRecordFail({
      error: { createRateCardError },
    }));
  }
}

function* updateRateCardRecord({ payload }) {
  const { rateCard, year } = payload;

  try {
    const options = {
      query: queryConfig.updateRateCardRecord,
      variables: {
        rateCard: {
          ...rateCard,
          recordId: rateCard.recordId,
        },
        year,
      },
    };
    const response = yield call(executeQuery, options);
    const { lastRatesVersion, updatedRateCards } =
      response.updateRateCardRecord;
    const rateCards = mapRates(updatedRateCards);
    const lastRates = sortRates(lastRatesVersion);
    return yield put(configurationsActions.updateRateCardRecordSuccess({
      rateCards,
      lastRates,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateRateCardRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateRateCardRecordError = yield call(parseError, error, options);

    return yield put(configurationsActions.updateRateCardRecordFail({
      error: { updateRateCardRecordError },
    }));
  }
}

function* deleteRateCardRecord({ payload: { recordId, isActive, year } }) {
  try {
    if (isActive) {
      yield put(modalConductorActions.clearModal());

      throw new Error("This Card cannot be deleted as it is connected with Client's card. Please, unassign all Client's cards before deleting this card.");
    }
    const mutation = queryConfig.deleteRateCardRecord({ recordId, year });
    const variables = {
      recordId,
      year,
    };
    const options = {
      mutation,
      variables,
    };

    const response = yield call(executeMutation, options);
    const { lastRatesVersion } = response.deleteRateCardRecord;
    const lastRates = sortRates(lastRatesVersion);
    return yield all([
      put(configurationsActions.deleteRateCardRecordSuccess({
        recordId,
        lastRates,
      })),
      put(modalConductorActions.clearModal()),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'deleteRateCardRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const deleteRateCardRecordError = yield call(parseError, error, options);
    return yield put(configurationsActions.deleteRateCardRecordFail({
      error: {
        deleteRateCardRecordError,
      },
    }));
  }
}

function* getEmailTemplates({ payload }) {
  const { date, ids } = payload;
  try {
    const options = {
      query: queryConfig.getEmailTemplates,
      variables: {
        year: date,
        ids,
      },
    };
    const { emailTemplates } = yield call(executeQuery, options);
    return yield put(configurationsActions.getEmailTemplatesSuccess({
      emailTemplates,
      date,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getEmailTemplateError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getEmailTemplatesError = yield call(parseError, error, options);

    return yield put(configurationsActions.getEmailTemplatesFail({
      error: { getEmailTemplatesError },
    }));
  }
}

function* createEmailTemplateRecord({ payload: { emailTemplate } }) {
  try {
    const { emailTemplateId, file, fileId, ...fields } = emailTemplate;
    const options = {
      query: queryConfig.createEmailTemplateRecord,
      variables: { fields },
    };
    const response = yield call(executeQuery, options);
    const {
      createEmailTemplate: { emailTemplate: createdEmailTemplate },
    } = response;
    return yield put(configurationsActions.createEmailTemplateRecordSuccess({
      emailTemplate: createdEmailTemplate,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createEmailTemplateError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createEmailTemplateError = yield call(parseError, error, options);
    return yield put(configurationsActions.createEmailTemplateRecordFail({
      error: { createEmailTemplateError },
    }));
  }
}

function* updateEmailTemplateRecord({ payload: { emailTemplate } }) {
  try {
    const { emailTemplateId, file, fileId, ...fields } = emailTemplate;
    const options = {
      query: queryConfig.updateEmailTemplateRecord,
      variables: {
        emailTemplateId,
        fields,
      },
    };
    const response = yield call(executeQuery, options);
    const {
      updateEmailTemplate: { emailTemplate: updatedEmailTemplate },
    } = response;
    return yield put(configurationsActions.updateEmailTemplateRecordSuccess({
      emailTemplate: updatedEmailTemplate,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateEmailTemplateRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const updateEmailTemplateRecordError = yield call(
      parseError,
      error,
      options
    );
    return yield put(configurationsActions.updateEmailTemplateRecordFail({
      error: { updateEmailTemplateRecordError },
    }));
  }
}

function* deleteEmailTemplateRecord({ payload }) {
  const { emailTemplateId, year } = payload;

  try {
    const mutation = queryConfig.deleteEmailTemplateRecord;
    const variables = {
      emailTemplateId,
      year,
    };
    const options = {
      mutation,
      variables,
    };

    yield call(executeMutation, options);

    return yield all([
      put(configurationsActions.deleteEmailTemplateRecordSuccess({
        emailTemplateId,
      })),
      put(modalConductorActions.clearModal()),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'deleteEmailTemplateRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const deleteEmailTemplateRecordError = yield call(
      parseError,
      error,
      options
    );
    return yield put(configurationsActions.deleteEmailTemplateRecordFail({
      error: {
        deleteEmailTemplateRecordError,
      },
    }));
  }
}

function* getHolidays({ payload: { date } }) {
  try {
    const options = {
      query: queryConfig.getHolidays,
      variables: {
        year: date,
      },
    };
    const response = yield call(executeQuery, options);
    const holidays = response.nonWorkingDays;
    return yield put(configurationsActions.getHolidaysSuccess({ holidays, date }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getHolidaysError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getHolidaysError = yield call(parseError, error, options);

    return yield put(configurationsActions.getHolidaysFail({
      error: { getHolidaysError },
    }));
  }
}

function* createHolidays({ payload: { fields } }) {
  try {
    const mutation = queryConfig.createNonWorkingDaysRecord;
    const variables = {
      fields,
    };
    const options = {
      mutation,
      variables,
    };
    const data = yield call(executeMutation, options);
    const holidays = data.createNonWorkingDaysRecord.nonWorkingDaysRecords;
    return yield all([
      put(configurationsActions.createHolidaysSuccess({
        holidays,
      })),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createHolidaysError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createHolidaysError = yield call(parseError, error, options);

    return yield put(configurationsActions.createHolidaysFail({
      error: {
        createHolidaysError,
      },
    }));
  }
}

function* removeHolidaysRecord({ payload: { recordId } }) {
  try {
    const mutation = queryConfig.removeHolidaysRecord({ recordId });
    const variables = {
      recordId,
    };
    const options = {
      mutation,
      variables,
    };

    yield call(executeMutation, options);

    return yield all([
      put(configurationsActions.removeHolidaysRecordSuccess({
        recordId,
      })),
      put(modalConductorActions.clearModal()),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'removeHolidaysError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const removeHolidaysError = yield call(parseError, error, options);

    return yield put(configurationsActions.removeHolidaysRecordFail({
      error: {
        removeHolidaysError,
      },
    }));
  }
}

function* uploadRatecardFileSaga({
  // eslint-disable-line consistent-return
  payload: { file, ratecardId, title },
  meta: { entityName },
}) {
  try {
    const { uploadFileError } = yield select(selectErrors);
    const requestURL = yield call(getRequestUrl, '/graphql');
    const accessToken = yield call(appStorage.getAccessToken);

    const variables = {
      fields: {
        ratecardId,
        title,
      },
    };

    const body = queryConfig.rateCardFileUpload(variables);
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      data: { rateCardFileUpload },
      errors,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        containerId: entityName,
        errors,
        activeErrors: uploadFileError,
      });

      return yield put(filesActions.uploadFileFail({
        error: {
          uploadFileError: listErrors,
        },
      }));
    }
    const response = rateCardFileUpload.rateCardFileUploadData;
    const channel = yield call(createUploadFileChannel, response, file);

    while (true) {
      const { progress = 0, error, success } = yield take(channel);

      if (error) {
        const { message } = error;

        getNotifications({
          containerId: entityName,
          error: message,
        });
        return yield put(filesActions.uploadFileFail({
          error: {
            uploadFileError: [message || 'unknown'],
          },
        }));
      }

      if (success) {
        if (uploadFileError) {
          uploadFileError.forEach((uploadError) => toast.dismiss(uploadError));
        }
        return yield all([
          put(filesActions.uploadFileSuccess()),
          call(getRateCard, {
            payload: { ratecardId },
          }),
        ]);
      }

      yield put(filesActions.updateUploadingProgress({
        progress: Math.round(progress * 100),
      }));
    }
  } catch (error) {
    const { message } = error;

    getNotifications({
      containerId: entityName,
      error: message,
    });
    return yield put(filesActions.uploadFileFail({
      error: {
        uploadFileError: [message || 'unknown'],
      },
    }));
  }
}

function* uploadEmailTemplateFileSaga({
  // eslint-disable-line consistent-return
  payload: { file, title, emailTemplateId },
  meta: { entityName },
}) {
  try {
    const { uploadFileError } = yield select(selectErrors);
    const requestURL = yield call(getRequestUrl, '/graphql');
    const accessToken = yield call(appStorage.getAccessToken);

    const variables = {
      clientFile: {},
      taskOrderFile: {},
      channelPartnerFile: {},
      emailFile: {
        emailTemplateId,
        title,
      },
    };
    const body = queryConfig.uploadFile(variables);
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };
    const {
      data: { uploadFile },
      errors,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        containerId: entityName,
        errors,
        activeErrors: uploadFileError,
      });

      return yield put(filesActions.uploadFileFail({
        error: {
          uploadFileError: listErrors,
        },
      }));
    }

    const {
      emailFileUploadData,
      file: { fileName, id, downloadUrl },
    } = uploadFile;
    const fileId = parseInt(id.replace(/[^0-9]/gi, ''), 10);
    const response = emailFileUploadData;
    const channel = yield call(createUploadFileChannel, response, file);

    while (true) {
      const { progress = 0, error, success } = yield take(channel);

      if (error) {
        const { message } = error;
        getNotifications({
          containerId: entityName,
          error: message,
        });
        return yield put(filesActions.uploadFileFail({
          error: {
            uploadFileError: [message || 'unknown'],
          },
        }));
      }

      if (success) {
        if (uploadFileError) {
          uploadFileError.forEach((uploadError) => toast.dismiss(uploadError));
        }
        return yield all([
          put(filesActions.uploadFileSuccess()),
          put(configurationsActions.uploadEmailTemplateFileSuccess({
            emailTemplateId,
            file: { fileName, id, title, downloadUrl },
            fileId,
          })),
        ]);
      }

      yield put(filesActions.updateUploadingProgress({
        progress: Math.round(progress * 100),
      }));
    }
  } catch (error) {
    const { message } = error;
    getNotifications({
      containerId: entityName,
      error: message,
    });
    const errorData = {
      uploadFileError: [message || 'unknown'],
    };
    return yield all([
      put(filesActions.uploadFileFail({
        error: errorData,
      })),
      put(configurationsActions.uploadEmailTemplateFileFail({
        error: errorData,
      })),
    ]);
  }
}

// Discount and Surcharge Management
function* getDiscountSurchargeRates() {
  try {
    const query = queryConfig.getDiscountSurchargeRates;
    const options = {
      query,
    };
    const { discountsAndSurcharges } = yield call(executeQuery, options);
    const { discountAndSurchargesRecords, billLock } = discountsAndSurcharges;

    return yield put(configurationsActions.getDiscountSurchargeRatesSuccess({
      discountSurchargeRates: discountAndSurchargesRecords,
      billLockDate: billLock,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getDiscountSurchargeRatesError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getDiscountSurchargeRatesError = yield call(
      parseError,
      error,
      options
    );

    return yield put(configurationsActions.getDiscountSurchargeRatesFail({
      error: { getDiscountSurchargeRatesError },
    }));
  }
}

function* updateDiscountSurchargeRates({ payload }) {
  const entityName = yield select(selectEntityName);

  try {
    const mutation = queryConfig.updateDiscountSurchargeRates;
    const options = {
      mutation,
      variables: {
        fields: {
          recordsInput: [...payload],
        },
      },
    };

    const { updateDiscountsAndSurchargesRecord } = yield call(
      executeMutation,
      options
    );
    const discountsAndSurchargesData = get(updateDiscountsAndSurchargesRecord, 'discountsAndSurchargesData', []);

    const { billLock, discountAndSurchargesRecords } = discountsAndSurchargesData;

    return yield put(configurationsActions.updateDiscountSurchargeRatesSuccess({
      discountSurchargeRates: discountAndSurchargesRecords,
      billLockDate: billLock,
    }));
  } catch (error) {
    const { message } = error;

    getNotifications({
      error: message,
      containerId: entityName,
    });
    return yield put(configurationsActions.updateDiscountSurchargeRatesFail({
      error: {
        updateDiscountSurchargeRatesError: [message || 'unknown'],
      },
    }));
  }
}

function* getHiqoContracts() {
  try {
    const options = {
      query: queryConfig.getHiqoContracts,
      variables: {},
    };
    const response = yield call(executeQuery, options);
    const { hiqoContracts } = response;
    return yield put(configurationsActions.getHiqoContractsSuccess({ hiqoContracts }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getHiqoContractsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getHiqoContractsError = yield call(parseError, error, options);

    return yield put(configurationsActions.getHiqoContractsFail({
      error: { getHiqoContractsError },
    }));
  }
}

function* createHiqContractsRecord({ payload: { fields } }) {
  try {
    const mutation = queryConfig.createHiqContractsRecord;
    const variables = {
      fields,
    };
    const options = {
      mutation,
      variables,
    };
    const data = yield call(executeMutation, options);
    const hiqoContracts = data.createHiqoContractsRecord.hiqoContractRecords;
    return yield all([
      put(configurationsActions.createHiqContractsRecordSuccess({
        hiqoContracts,
      })),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createHiqContractsRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createHiqContractsRecordError = yield call(
      parseError,
      error,
      options
    );

    return yield put(configurationsActions.createHiqContractsRecordFail({
      error: {
        createHiqContractsRecordError,
      },
    }));
  }
}

function* removeHiqoContractsRecord({ payload: { recordId } }) {
  try {
    const mutation = queryConfig.removeHiqoContractsRecord({ recordId });
    const variables = {
      recordId,
    };
    const options = {
      mutation,
      variables,
    };

    yield call(executeMutation, options);

    return yield all([
      put(configurationsActions.removeHiqoContractsRecordSuccess({
        recordId,
      })),
      put(modalConductorActions.clearModal()),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'removeHiqoContractError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const removeHiqoContractError = yield call(parseError, error, options);

    return yield put(configurationsActions.removeHiqoContractsRecordFail({
      error: {
        removeHiqoContractError,
      },
    }));
  }
}

export function* checkRateCardNotificationsStatusWatcher(...args) {
  yield race({
    task: call(checkRateCardNotificationsStatus, ...args),
    cancel: take(clientRateCardUpdateNotificationsTypes.STOP_CHECK_RATECARD_NOTIFICATIONS_STATUS),
  });
}

export function* checkEmailsNotificationsStatusWatcher(...args) {
  yield race({
    task: call(checkEmailsNotificationsStatus, ...args),
    cancel: take(emailTemplateNotificationsTypes.STOP_CHECK_EMAILS_NOTIFICATIONS_STATUS),
  });
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(
      configurationsActionsTypes.UPLOAD_RATECARD_FILE,
      waitForAuthorization(uploadRatecardFileSaga)
    ),
    yield takeEvery(
      configurationsActionsTypes.UPLOAD_EMAIL_TEMPLATE_FILE,
      waitForAuthorization(uploadEmailTemplateFileSaga)
    ),
    yield takeEvery(
      clientRateCardUpdateNotificationsTypes.CHECK_RATECARD_NOTIFICATIONS_STATUS,
      waitForAuthorization(checkRateCardNotificationsStatusWatcher)
    ),
    yield takeEvery(
      emailTemplateNotificationsTypes.CHECK_EMAILS_NOTIFICATIONS_STATUS,
      waitForAuthorization(checkEmailsNotificationsStatusWatcher)
    ),
    yield takeLatest(
      configurationsActionsTypes.GET_HOLIDAYS,
      waitForAuthorization(getHolidays)
    ),
    yield takeLatest(
      configurationsActionsTypes.CREATE_HOLIDAYS_RECORD,
      waitForAuthorization(createHolidays)
    ),
    yield takeLatest(
      configurationsActionsTypes.REMOVE_HOLIDAYS_RECORD,
      waitForAuthorization(removeHolidaysRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.GET_HIQO_CONTRACTS,
      waitForAuthorization(getHiqoContracts)
    ),
    yield takeLatest(
      configurationsActionsTypes.CREATE_HIQO_CONTRACTS_RECORD,
      waitForAuthorization(createHiqContractsRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.REMOVE_HIQO_CONTRACTS_RECORD,
      waitForAuthorization(removeHiqoContractsRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.GET_RATE_CARDS,
      waitForAuthorization(getRateCards)
    ),
    yield takeLatest(
      configurationsActionsTypes.GET_RATE_CARD,
      waitForAuthorization(getRateCard)
    ),
    yield takeLatest(
      configurationsActionsTypes.CREATE_RATE_CARD_RECORD,
      waitForAuthorization(createRateCardRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.UPDATE_RATE_CARD_RECORD,
      waitForAuthorization(updateRateCardRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.REMOVE_RATE_CARD_RECORD,
      waitForAuthorization(deleteRateCardRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.GET_EMAIL_TEMPLATES,
      waitForAuthorization(getEmailTemplates)
    ),
    yield takeLatest(
      configurationsActionsTypes.CREATE_EMAIL_TEMPLATE_RECORD,
      waitForAuthorization(createEmailTemplateRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.UPDATE_EMAIL_TEMPLATE_RECORD,
      waitForAuthorization(updateEmailTemplateRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.REMOVE_EMAIL_TEMPLATE_RECORD,
      waitForAuthorization(deleteEmailTemplateRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.GET_EXCHANGE_RATES,
      waitForAuthorization(getExchangeRates)
    ),
    yield takeLatest(
      configurationsActionsTypes.UPDATE_EXCHANGE_RATES,
      waitForAuthorization(updateExchangeRates)
    ),
    yield takeLatest(
      configurationsActionsTypes.GET_COMMISSION_RATES,
      waitForAuthorization(getCommissionRates)
    ),
    yield takeLatest(
      configurationsActionsTypes.REMOVE_COMMISSION_RECORD,
      waitForAuthorization(removeCommissionRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.CREATE_COMMISSION_RECORD,
      waitForAuthorization(createCommissionRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.UPDATE_COMMISSION_RECORD,
      waitForAuthorization(updateCommissionRecord)
    ),
    yield takeLatest(
      configurationsActionsTypes.GET_DISCOUNT_SURCHARGE_RATES,
      waitForAuthorization(getDiscountSurchargeRates)
    ),
    yield takeLatest(
      configurationsActionsTypes.UPDATE_DISCOUNT_SURCHARGE_RATES,
      waitForAuthorization(updateDiscountSurchargeRates)
    ),
    yield takeLatest(
      clientRateCardUpdateNotificationsTypes.SEND_RATECARD_NOTIFICATIONS,
      waitForAuthorization(sendRatecardNotificationsEmails)
    ),
    yield takeLatest(
      clientRateCardUpdateNotificationsTypes.GET_RATECARD_NOTIFICATIONS,
      waitForAuthorization(getRateCardsNotifications)
    ),
    yield takeLatest(
      clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS,
      waitForAuthorization(updateRateCardNotifications)
    ),
    yield takeLatest(
      clientRateCardUpdateNotificationsTypes.CREATE_RATECARD_NOTIFICATIONS_VERSION,
      waitForAuthorization(createRatecardNotificationsVersion)
    ),
    yield takeLatest(
      clientRateCardUpdateNotificationsTypes.DELETE_RATECARD_NOTIFICATIONS_VERSION,
      waitForAuthorization(deleteRatecardNotificationsVersion)
    ),
    yield takeLatest(
      clientRateCardUpdateNotificationsTypes.UPDATE_RATECARD_NOTIFICATIONS_STATUS,
      waitForAuthorization(updateRateCardNotificationsStatus)
    ),
    yield takeLatest(
      emailTemplateNotificationsTypes.GET_EMAIL_TEMPLATE_NOTIFICATIONS,
      waitForAuthorization(getEmailTemplateNotifications)
    ),
    yield takeLatest(
      emailTemplateNotificationsTypes.UPDATE_EMAIL_TEMPLATE_NOTIFICATIONS,
      waitForAuthorization(updateEmailTemplateNotifications)
    ),
    yield takeLatest(
      emailTemplateNotificationsTypes.CREATE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION,
      waitForAuthorization(createEmailTemplateNotificationsVersion)
    ),
    yield takeLatest(
      emailTemplateNotificationsTypes.DELETE_EMAIL_TEMPLATE_NOTIFICATIONS_VERSION,
      waitForAuthorization(deleteEmailTemplateNotificationsVersion)
    ),
    yield takeLatest(
      emailTemplateNotificationsTypes.UPDATE_EMAILS_NOTIFICATIONS_STATUS,
      waitForAuthorization(updateEmailsNotificationsStatus)
    ),
    yield takeLatest(
      emailTemplateNotificationsTypes.SEND_EMAIL_TEMPLATE_NOTIFICATIONS_EMAILS,
      waitForAuthorization(sendEmailTemplateNotificationsEmails)
    ),
  ]);
}
