import { toNumber } from 'lodash';
import moment from 'moment';

import {
  viewBoxCssRules,
  rateInputCssRules,
  formHeadingCssRules,
  multiCellWrapperCssRules,
  getFormRowWrapperCssRules,
  monthsFormHeadingCssRules,
  tableHeaderCssRules,
} from './styles';

const filedArrayRootName = 'exchangeRates';

export const exchangeRatesTableHeaderModel = {
  rules: {
    css: {
      tableWrapperCssRules: tableHeaderCssRules,
      gridTemplateConfig: formHeadingCssRules,
    },
  },
  dataTemplate: [
    {
      columnName: 'Years',
      cssRules: `
        {
          &.grid-unit {
            padding-left: 1.6rem;
            justify-content: flex-start;
          }
      }
      `,
    },
    {
      columnName: 'Currency',
      cssRules: `{
        &.grid-unit {
          justify-content: flex-start;
        }
      }`,
      withoutControls: true,
    },
    ...moment.monthsShort().map((monthName) => ({
      columnName: monthName,
      cssRules: monthsFormHeadingCssRules,
      withoutControls: true,
    })),
  ],
};

const currentYear = moment().year();
const lockedDate = moment().subtract(4, 'month');

export const exchangeRatesManagementFormTemplate = [{
  stylesTemplate: {
    cssRules: `
      grid-row-gap: 0;
      margin-top: 0;
    `,
  },
  formData: [{
    isFieldArray: true,
    name: filedArrayRootName,
    valueGetter: ({ exchangeRates }) => {
      return {
        value: exchangeRates,
        lineGetter: ({
          index,
          lineValues,
        }) => {
          const {
            year,
            monthData,
          } = lineValues || {};

          return {
            lineId: year,
            line: [
              {
                type: 'viewBox',
                fieldData: {
                  value: year,
                },
                cssRules: `
                padding-left: 1.6rem;
                font-weight: 500;
                ${currentYear === year ? 'color: #D61F26;' : 'color: rgba(000,000,000,0.87);'}
                ${viewBoxCssRules}
              `,
              },
              {
                isMultiple: true,
                cssRules: multiCellWrapperCssRules,
                data: [{
                  type: 'viewBox',
                  fieldData: {
                    value: '1 USD / 1 PLN',
                  },
                  cssRules: viewBoxCssRules,
                }, {
                  type: 'viewBox',
                  fieldData: {
                    value: '1 USD / 1 RUR',
                  },
                  cssRules: viewBoxCssRules,
                }],
              },
              ...monthData.map(({ usdPln, usdRur, month }) => {
                const exchangeRateValuePln = usdPln ? toNumber(usdPln).toFixed(4) : '00.00';
                const exchangeRateValueRur = usdRur ? toNumber(usdRur).toFixed(4) : '00.00';
                return {
                  isMultiple: true,
                  cssRules: multiCellWrapperCssRules,
                  data: [
                    {
                      type: 'text',
                      name: `${filedArrayRootName}[${index}].monthData[${month - 1}].usdPln`,
                      label: 'pln',
                      isLocked: moment(`${year}-${month <= 9 ? `0${month}` : month}-01`).isBefore(lockedDate),
                      fieldData: {
                        placeholder: exchangeRateValuePln,
                      },
                      validationRules: {
                        fixedTo: 4,
                        isRates: true,
                        decimalScale: 4,
                        isNumeric: true,
                        isRequired: true,
                        maxValue: 99999.9999,
                      },
                      cssRules: `
                    ${rateInputCssRules}
                  `,
                    },
                    {
                      type: 'text',
                      name: `${filedArrayRootName}[${index}].monthData[${month - 1}].usdRur`,
                      label: 'rur',
                      isLocked: moment(`${year}-${month <= 9 ? `0${month}` : month}-01`).isBefore(lockedDate),
                      fieldData: {
                        placeholder: exchangeRateValueRur,
                      },
                      validationRules: {
                        fixedTo: 4,
                        isRates: true,
                        decimalScale: 4,
                        isNumeric: true,
                        isRequired: true,
                        maxValue: 99999.9999,
                      },
                      cssRules: rateInputCssRules,
                    },
                  ],
                };
              })],
            stylesTemplate: {
              cssRules: getFormRowWrapperCssRules({ index }),
            },
          };
        },
      };
    },
  }],
}];
