import React, { useLayoutEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import ModalConductor from 'containers/modal-conductor';
import Router from 'containers/router';
import { authActions } from 'core/auth/actions';
import {
  selectUserFullName,
  selectUserGroup,
} from 'core/auth/selectors';
import { commonActions } from 'core/common/actions';
import { filesActions } from 'core/files/actions';
import { utilizationReportUrl } from 'core/files/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import compose from 'lodash/fp/compose';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

const App = (props) => {
  const { getTokenFromCookies } = props;
  // eslint-disable-next-line react/destructuring-assignment

  useLayoutEffect(() => {
    getTokenFromCookies();
  }, []);

  return (
    <>
      <Router {...props} />
      <ModalConductor />
    </>
  );
};

App.propTypes = {
  userFullName: PropTypes.string.isRequired,
  userGroup: PropTypes.string.isRequired,
  getTokenFromCookies: PropTypes.func.isRequired,
  generateUtilizationReport: PropTypes.func.isRequired,
  generateBookingReport: PropTypes.func.isRequired,
  userLogout: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userFullName: selectUserFullName,
  userGroup: selectUserGroup,
  utilizationReportUrl,
});

const mapDispatchToProps = {
  changeOrder: commonActions.changeOrder,
  getTokenFromCookies: authActions.getTokenFromCookies,
  userLogout: authActions.userLogout,
  changeCurrentModal: modalConductorActions.changeCurrentModal,
  generateUtilizationReport: filesActions.generateUtilizationReport,
  generateBookingReport: filesActions.generateBookingReport,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
