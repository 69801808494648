import { waitForAuthorization } from 'core/auth/sagas';
import {
  contractManagementActions,
  CREATE_CONTRACT_MANAGEMENT_RECORD,
  DELETE_CONTRACT_MANAGEMENT_RECORD,
  GET_CONTRACT_MANAGEMENT_RECORDS,
  UPDATE_CONTRACT_MANAGEMENT_RECORD,
  UPLOAD_ATTACHMENT_FILE,
} from 'core/delivery/contractManagement/actions';

import { recordIdName } from 'core/delivery/contractManagement/constants';
import { selectDevstaffId, selectEntityName, selectErrors } from 'core/delivery/selectors';
import { removeFileExtension } from 'core/utils';
import { get } from 'lodash';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { createUploadFileChannel, executeMutation, executeQuery, parseError } from 'utils/request';

import { queryConfig } from './queries';

// eslint-disable-next-line consistent-return
function* getContractManagementRecords() {
  try {
    const devstaffId = yield select(selectDevstaffId);
    const query = queryConfig.devstaffContractManagementRecords;
    const options = {
      query,
      variables: {
        devstaffId,
      },
    };
    const { devstaffContractManagementRecords } = yield call(executeQuery, options);

    return yield put(contractManagementActions.getContractManagementRecordsSuccess({
      records: devstaffContractManagementRecords,
      memoDevstaffId: devstaffId,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getContractManagementRecordsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getContractManagementRecordsError = yield call(parseError, error, options);

    yield put(contractManagementActions.getContractManagementRecordsFail({
      error: {
        getContractManagementRecordsError,
      },
    }));
  }
}

// eslint-disable-next-line consistent-return
function* createContractManagementRecord({
  payload,
}) {
  try {
    const {
      contractManagementRecordId,
      formState,
      file,
      ...fields
    } = payload;
    const fileName = get(file, 'name');
    const devstaffId = yield select(selectDevstaffId);
    const mutation = queryConfig.createContractManagementRecord;
    const options = {
      mutation,
      variables: {
        devstaffId,
        fields: {
          ...fields,
          ...(fileName && {
            fileName: removeFileExtension(fileName),
          }),
        },
      },
    };
    const { createContractManagementRecord: data } = yield call(executeMutation, options);
    const allowedToEdit = get(data, 'contractManagementRecord[0].allowedForEdit');

    let result = false;

    if (fileName && allowedToEdit) {
      result = yield call(uploadAttachmentFile, {
        recordId: get(data, `contractManagementRecord[0].${recordIdName}`),
        fileUploadData: get(data, 'fileUploadData'),
        file,
      });
    }

    if (result) {
      yield call(uploadContractManagementFile, {
        contractManagementRecordId: get(data, `contractManagementRecord[0].${recordIdName}`),
      });
    }

    return yield put(contractManagementActions.createContractManagementRecordSuccess({
      record: {
        ...get(data, 'contractManagementRecord[0]'),
        fileName: get(file, 'name'),
        ...(!result && {
          fileName: null,
          downloadUrl: null,
        }),
      },
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createContractManagementRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createContractManagementRecordError = yield call(parseError, error, options);

    yield put(contractManagementActions.createContractManagementRecordFail({
      error: {
        createContractManagementRecordError,
      },
    }));
  }
}

// eslint-disable-next-line consistent-return
function* updateContractManagementRecord({
  payload,
}) {
  const { contractManagementRecordId, file, allowedForEdit, allowedForDelete, ...fields } = payload;
  const fileName = get(file, 'name', '');
  if (fileName) {
    fields.fileName = removeFileExtension(fileName);
  }
  try {
    const query = queryConfig.updateContractManagementRecord;
    const options = {
      query,
      variables: {
        contractManagementRecordId: Number(contractManagementRecordId),
        fields: {
          ...fields,
        },
      },
    };

    const { updateContractManagementRecord: data } = yield call(executeQuery, options);

    const fileUploadData = get(data, 'fileUploadData', false);

    let result = false;

    if (fileUploadData && allowedForEdit) {
      result = yield call(uploadAttachmentFile, {
        recordId: get(data, `contractManagementRecord.${recordIdName}`),
        fileUploadData: get(data, 'fileUploadData'),
        file,
      });
    }

    if (result) {
      yield call(uploadContractManagementFile, {
        contractManagementRecordId: get(data, `contractManagementRecord.${recordIdName}`),
      });
    }

    return yield put(contractManagementActions.updateContractManagementRecordSuccess({
      record: {
        ...get(data, 'contractManagementRecord'),
      },
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'updateContractManagementRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const createContractManagementRecordError = yield call(parseError, error, options);

    yield put(contractManagementActions.createContractManagementRecordFail({
      error: {
        createContractManagementRecordError,
      },
    }));
  }
}

// eslint-disable-next-line consistent-return
function* deleteContractManagementRecord({
  payload,
}) {
  try {
    const contractManagementRecordId = get(payload, recordIdName);
    const query = queryConfig.deleteContractManagementRecord;
    const options = {
      query,
      variables: {
        contractManagementRecordId,
      },
    };

    yield call(executeQuery, options);

    return yield put(contractManagementActions.deleteContractManagementRecordSuccess(contractManagementRecordId));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'deleteContractManagementRecordError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const deleteContractManagementRecordError = yield call(parseError, error, options);

    yield put(contractManagementActions.deleteContractManagementRecordFail({
      error: {
        deleteContractManagementRecordError,
      },
    }));
  }
}

function* fileUploadError(error) {
  const errors = yield select(selectErrors);
  const entityName = yield select(selectEntityName);
  const storedErrors = get(errors, 'uploadFileError', []);
  const options = {
    entityName,
    storedErrors,
  };
  const uploadFileError = yield call(parseError, error, options);

  return yield put(contractManagementActions.uploadAttachmentFileFail({
    error: {
      uploadFileError,
    },
  }));
}

function* uploadAttachmentFile({
  fileUploadData,
  file,
}) {
  const uploadError = {
    message: 'File upload error. Please try again.',
  };

  try {
    const channel = yield call(createUploadFileChannel, fileUploadData, file);

    while (true) {
      const action = yield take(channel);
      const { error: uploadFileError, success } = action;

      if (uploadFileError) {
        yield call(fileUploadError, uploadError);

        return false;
      }

      if (success) {
        return success;
      }
    }
  } catch (error) {
    // eslint-disable-next-line consistent-return
    return yield call(fileUploadError, uploadError);
  }
}

// eslint-disable-next-line consistent-return
function* uploadContractManagementFile({
  contractManagementRecordId,
}) {
  try {
    const query = queryConfig.uploadContractManagementFile;
    const options = {
      query,
      variables: {
        contractManagementRecordId,
      },
    };
    return yield call(executeQuery, options);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'uploadContractManagementFile', []);
    const options = {
      entityName,
      storedErrors,
    };
    const deleteContractManagementRecordError = yield call(parseError, error, options);

    yield put(contractManagementActions.deleteContractManagementRecordFail({
      error: {
        deleteContractManagementRecordError,
      },
    }));
  }
}

function* getContractManagementRecordsWatcher() {
  yield takeLatest(GET_CONTRACT_MANAGEMENT_RECORDS, waitForAuthorization(getContractManagementRecords));
}

function* createContractManagementRecordWatcher() {
  yield takeLatest(CREATE_CONTRACT_MANAGEMENT_RECORD, waitForAuthorization(createContractManagementRecord));
}

function* updateContractManagementRecordWatcher() {
  yield takeLatest(UPDATE_CONTRACT_MANAGEMENT_RECORD, waitForAuthorization(updateContractManagementRecord));
}

function* deleteContractManagementRecordWatcher() {
  yield takeLatest(DELETE_CONTRACT_MANAGEMENT_RECORD, waitForAuthorization(deleteContractManagementRecord));
}

function* uploadAttachmentFileFailWatcher() {
  yield takeLatest(UPLOAD_ATTACHMENT_FILE, waitForAuthorization(uploadAttachmentFile));
}

function* uploadContractManagementFileWatcher() {
  yield takeLatest(UPLOAD_ATTACHMENT_FILE, waitForAuthorization(uploadContractManagementFile));
}
export default [
  getContractManagementRecordsWatcher,
  createContractManagementRecordWatcher,
  updateContractManagementRecordWatcher,
  deleteContractManagementRecordWatcher,
  uploadAttachmentFileFailWatcher,
  uploadContractManagementFileWatcher,
];
